export function decodeJwt(token: string) {
  const base64Url = token.split('.')[1]
  if (!base64Url) return { jti: '', sub: 'user' }
  const base64 = base64Url.replace('-', '+').replace('_', '/')
  return JSON.parse(atob(base64))
}

export function getJtiFromToken(token: string): string {
  const decoded = decodeJwt(token) || {}
  return decoded.jti
}
