export enum UserRole {
  'ROLE_MASTER_ADMIN' = 'ROLE_MASTER_ADMIN',
  'ROLE_REALM_ADMIN' = 'ROLE_REALM_ADMIN',
  'ROLE_DATA_ENGINEER' = 'ROLE_DATA_ENGINEER',
  'ROLE_APPLICATION_DESIGNER' = 'ROLE_APPLICATION_DESIGNER',
  'ROLE_USER' = 'ROLE_USER',
  'ROLE_TECHNICIAN' = 'ROLE_TECHNICIAN',
  'ROLE_ML_ENGINEER' = 'ROLE_ML_ENGINEER',
  'ROLE_REPORT_DEVELOPER' = 'ROLE_REPORT_DEVELOPER',
}

export type UserAuthority =
  | 'S_APPLICATIONS_DASHBOARDS_ALL_APPLICATIONS'
  | 'S_APPLICATIONS_DASHBOARDS_APPLICATIONS_GET_UNPUBLISHED'
  | 'S_APPLICATIONS_DASHBOARDS_APPLICATION_GET_DASHBOARDS'
  | 'S_APPLICATIONS_DASHBOARDS_COPY_APPLICATION'
  | 'S_APPLICATIONS_DASHBOARDS_COPY_DASHBOARD'
  | 'S_APPLICATIONS_DASHBOARDS_CREATE_APPLICATION'
  | 'S_APPLICATIONS_DASHBOARDS_CREATE_DASHBOARD'
  | 'S_APPLICATIONS_DASHBOARDS_DELETE_APPLICATION'
  | 'S_APPLICATIONS_DASHBOARDS_DELETE_DASHBOARD'
  | 'S_APPLICATIONS_DASHBOARDS_EDIT_ACCESS_GROUP'
  | 'S_APPLICATIONS_DASHBOARDS_GET_ONE_APPLICATION'
  | 'S_APPLICATIONS_DASHBOARDS_GET_ONE_DASHBOARD'
  | 'S_APPLICATIONS_DASHBOARDS_PUBLISH_APPLICATION'
  | 'S_APPLICATIONS_DASHBOARDS_UNPUBLISH_APPLICATION'
  | 'S_APPLICATIONS_DASHBOARDS_UPDATE_APPLICATION'
  | 'S_APPLICATIONS_DASHBOARDS_UPDATE_DASHBOARD'
  | 'S_APPLICATIONS_DASHBOARDS_UPDATE_DASHBOARD_PREVIEW'
  | 'S_APPLICATIONS_DASHBOARDS_VIEW_ACCESS_GROUP'
  | 'S_DATASOURCES_ACCESS_GROUPS_GET_SCHEMAS'
  | 'S_DATASOURCES_ACCESS_GROUPS_GET_SYSTEM_DATASOURCES'
  | 'S_DATASOURCES_ACCESS_GROUPS_GET_TABLES'
  | 'S_DATASOURCES_ACCESS_GROUPS_SET_SCHEMAS'
  | 'S_DATASOURCES_ACCESS_GROUPS_SET_SYSTEM_DATASOURCES'
  | 'S_DATASOURCES_ACCESS_GROUPS_SET_TABLES'
  | 'S_DATASOURCES_ACCESS_GROUPS_UPDATE_SCHEMAS'
  | 'S_DATASOURCES_ACCESS_GROUPS_UPDATE_SYSTEM_DATASOURCES'
  | 'S_DATASOURCES_COMMON_DB_TEST_CONNECTION'
  | 'S_DATASOURCES_COMMON_GET_DEFAULT_SETTINGS'
  | 'S_DATASOURCES_COMMON_TEST_CONNECTION'
  | 'S_DATASOURCES_EXTERNAL_DATASOURCES_ACTUALIZE'
  | 'S_DATASOURCES_EXTERNAL_DATASOURCES_ACTUALIZE_OBJECTS'
  | 'S_DATASOURCES_EXTERNAL_DATASOURCES_CREATE'
  | 'S_DATASOURCES_EXTERNAL_DATASOURCES_DELETE'
  | 'S_DATASOURCES_EXTERNAL_DATASOURCES_GET_BY_REALM'
  | 'S_DATASOURCES_EXTERNAL_DATASOURCES_GET_BY_REALM_LITE'
  | 'S_DATASOURCES_EXTERNAL_DATASOURCES_GET_ONE'
  | 'S_DATASOURCES_EXTERNAL_DATASOURCES_GET_TABLES'
  | 'S_DATASOURCES_EXTERNAL_DATASOURCES_LOAD_OBJECTS'
  | 'S_DATASOURCES_EXTERNAL_DATASOURCES_OBJECTS'
  | 'S_DATASOURCES_EXTERNAL_DATASOURCES_OBJECTS_STRUCT'
  | 'S_DATASOURCES_EXTERNAL_DATASOURCES_PREVIEW'
  | 'S_DATASOURCES_EXTERNAL_DATASOURCES_TEST_CONNECTION'
  | 'S_DATASOURCES_EXTERNAL_DATASOURCES_UPDATE'
  | 'S_DATASOURCES_FIELDS_GET_BY_GUID'
  | 'S_DATASOURCES_FIELDS_GET_DATA'
  | 'S_DATASOURCES_FIELDS_UPDATE'
  | 'S_DATASOURCES_FIELDS_UPDATE_PRESENTATION_NAME'
  | 'S_DATASOURCES_OBJECTS_GET_BY_GUIDS'
  | 'S_DATASOURCES_OBJECTS_GET_FIELDS'
  | 'S_DATASOURCES_OBJECTS_GET_FIELDS_LITE'
  | 'S_DATASOURCES_OBJECTS_GET_ONE'
  | 'S_DATASOURCES_OBJECTS_PREVIEW_DATA'
  | 'S_DATASOURCES_OBJECTS_PUBLISH'
  | 'S_DATASOURCES_OBJECTS_UNPUBLISH'
  | 'S_DATASOURCES_OBJECTS_UPDATE'
  | 'S_DATASOURCES_OBJECTS_UPDATE_PRESENTATION_NAME'
  | 'S_DATASOURCES_SCHEMAS_ACTUALIZE'
  | 'S_DATASOURCES_SCHEMAS_CREATE'
  | 'S_DATASOURCES_SCHEMAS_DELETE'
  | 'S_DATASOURCES_SCHEMAS_FULL_VIEW'
  | 'S_DATASOURCES_SCHEMAS_GET_OBJECTS'
  | 'S_DATASOURCES_SCHEMAS_GET_OBJECTS_ALL'
  | 'S_DATASOURCES_SCHEMAS_GET_OBJECTS_ALL_LITE'
  | 'S_DATASOURCES_SCHEMAS_GET_OBJECTS_LITE'
  | 'S_DATASOURCES_SCHEMAS_ONE'
  | 'S_DATASOURCES_SCHEMAS_UPDATE'
  | 'S_DATASOURCES_SYSTEM_DATASOURCES_ACTUALIZE'
  | 'S_DATASOURCES_SYSTEM_DATASOURCES_ACTUALIZE_OBJECTS'
  | 'S_DATASOURCES_SYSTEM_DATASOURCES_GET_BY_REALM'
  | 'S_DATASOURCES_SYSTEM_DATASOURCES_GET_BY_REALM_ALL'
  | 'S_DATASOURCES_SYSTEM_DATASOURCES_GET_BY_REALM_ALL_LITE'
  | 'S_DATASOURCES_SYSTEM_DATASOURCES_GET_BY_REALM_LITE'
  | 'S_DATASOURCES_SYSTEM_DATASOURCES_GET_ONE'
  | 'S_DATASOURCES_SYSTEM_DATASOURCES_GET_SOURCE_OBJECTS_NAMES'
  | 'S_DATASOURCES_SYSTEM_DATASOURCES_GET_SOURCE_SCHEMAS_NAMES'
  | 'S_DATASOURCES_SYSTEM_DATASOURCES_OBJECTS'
  | 'S_DATASOURCES_SYSTEM_DATASOURCES_OBJECTS_ALL'
  | 'S_DATASOURCES_SYSTEM_DATASOURCES_OBJECTS_ALL_LITE'
  | 'S_DATASOURCES_SYSTEM_DATASOURCES_OBJECTS_LITE'
  | 'S_DATASOURCES_SYSTEM_DATASOURCES_PREVIEW'
  | 'S_DATASOURCES_SYSTEM_DATASOURCES_SCHEMAS'
  | 'S_DATASOURCES_SYSTEM_DATASOURCES_SCHEMAS_ALL'
  | 'S_DATASOURCES_SYSTEM_DATASOURCES_SCHEMAS_ALL_LITE'
  | 'S_DATASOURCES_SYSTEM_DATASOURCES_SCHEMAS_LITE'
  | 'S_DATASOURCES_SYSTEM_DATASOURCES_SCHEMA_GUID_BY_NAME'
  | 'S_DATASOURCES_SYSTEM_DATASOURCES_TEST_CONNECTION'
  | 'S_DATASOURCES_TYPES_AUTH'
  | 'S_DATASOURCES_TYPES_DATASOURCES'
  | 'S_DATASOURCES_TYPES_DATA_PROVIDERS'
  | 'S_DATASOURCES_TYPES_DEFINE_AS'
  | 'S_DATASOURCES_TYPES_TABLES'
  | 'S_DATA_MODELS_MODELS_CREATE'
  | 'S_DATA_MODELS_MODELS_DELETE'
  | 'S_DATA_MODELS_MODELS_GET_ALL'
  | 'S_DATA_MODELS_MODELS_GET_ONE'
  | 'S_DATA_MODELS_MODELS_RESTORE'
  | 'S_DATA_MODELS_MODELS_UPDATE'
  | 'S_ETL_MIDDLEWARE_DATA_ENGINEER'
  | 'S_ETL_MIDDLEWARE_EDIT_ACCESS_GROUP'
  | 'S_FILE_MANAGER_DATA_ENGINEER'
  | 'S_FILTERS_CROSS_FILTERS_CLEAR_STEPS'
  | 'S_FILTERS_CROSS_FILTERS_CREATE_STEP'
  | 'S_FILTERS_CROSS_FILTERS_DELETE_OLDER_STEPS'
  | 'S_FILTERS_CROSS_FILTERS_GET_STEPS'
  | 'S_FILTERS_GLOBAL_FILTERS_CREATE'
  | 'S_FILTERS_GLOBAL_FILTERS_DELETE'
  | 'S_FILTERS_GLOBAL_FILTERS_ONE'
  | 'S_FILTERS_GLOBAL_FILTERS_UPDATE'
  | 'S_FILTERS_GLOBAL_GET_FOR_APPLICATION'
  | 'S_FILTERS_GLOBAL_GET_HIDDEN_FOR_APPLICATION'
  | 'S_FILTERS_USER_GLOBAL_FILTERS_DISABLE'
  | 'S_FILTERS_USER_GLOBAL_FILTERS_ENABLE'
  | 'S_FILTERS_USER_GLOBAL_FILTERS_UPDATE'
  | 'S_GEO_DATA_COORDINATES'
  | 'S_GEO_DATA_QUERY'
  | 'S_ML_CREATE_EXPERIMENT'
  | 'S_ML_CREATE_LINK'
  | 'S_ML_CREATE_MODEL'
  | 'S_ML_CREATE_PATTERN'
  | 'S_ML_CREATE_STEP'
  | 'S_ML_DELETE_EXPERIMENT'
  | 'S_ML_DELETE_LIBRARY_MODEL'
  | 'S_ML_DELETE_LINK'
  | 'S_ML_DELETE_MODEL'
  | 'S_ML_DELETE_PATTERN'
  | 'S_ML_DELETE_RUN'
  | 'S_ML_DELETE_STEP'
  | 'S_ML_DELETE_VERSION'
  | 'S_ML_EXPERIMENTS_EDIT_ACCESS_GROUP'
  | 'S_ML_EXPERIMENTS_VIEW_ACCESS_GROUP'
  | 'S_ML_EXTRACT_LIBRARY_MODEL'
  | 'S_ML_GET_EXPERIMENT'
  | 'S_ML_GET_LIBRARY_MODEL'
  | 'S_ML_GET_LINK'
  | 'S_ML_GET_MODEL'
  | 'S_ML_GET_PATTERN'
  | 'S_ML_GET_RUN'
  | 'S_ML_GET_STEP'
  | 'S_ML_GET_VERSION'
  | 'S_ML_PATTERNS_EDIT_ACCESS_GROUP'
  | 'S_ML_PATTERNS_VIEW_ACCESS_GROUP'
  | 'S_ML_RUN_STEP'
  | 'S_ML_RUN_VERSION'
  | 'S_ML_SAVE_LIBRARY_MODEL'
  | 'S_ML_UPDATE_EXPERIMENT'
  | 'S_ML_UPDATE_LIBRARY_MODEL'
  | 'S_ML_UPDATE_MODEL'
  | 'S_ML_UPDATE_PATTERN'
  | 'S_ML_UPDATE_RUN'
  | 'S_ML_UPDATE_STEP'
  | 'S_ML_UPDATE_VERSION'
  | 'S_OBJECT_STORAGE_DELETE'
  | 'S_OBJECT_STORAGE_DOWNLOAD'
  | 'S_OBJECT_STORAGE_UPLOAD'
  | 'S_OBJECT_STORAGE_UPLOAD_TMP'
  | 'S_REPORT_REPORT_DEVELOPER'
  | 'S_USER_ADD_USER_ACCESS_GROUP'
  | 'S_USER_BLOCK_USER_BY_REALM'
  | 'S_USER_CHANGE_OWN_PASSWORD'
  | 'S_USER_CREATE_ACCESS_GROUP'
  | 'S_USER_CREATE_ETL_SCHEMA'
  | 'S_USER_DELETE_ACCESS_GROUP'
  | 'S_USER_DELETE_ETL_SCHEMA'
  | 'S_USER_EDIT_ACCESS_GROUP'
  | 'S_USER_GET_ACCESS_GROUP'
  | 'S_USER_GET_ETL_SCHEMA'
  | 'S_USER_GET_ETL_SCHEMA_NAMES'
  | 'S_USER_GET_REALM'
  | 'S_USER_GET_REALM_USER_MAPPING_BY_REALM'
  | 'S_USER_GET_ROLE_GROUP'
  | 'S_USER_MANAGE_USERS_OF_ROLE_GROUP'
  | 'S_USER_UPDATE_ACCESS_GROUP'
  | 'S_USER_UPDATE_ETL_SCHEMA'
  | 'S_USER_VIEW_ACCESS_GROUP'
  | 'S_WIDGETS_ACTIVATION_RULES_CREATE'
  | 'S_WIDGETS_ACTIVATION_RULES_DELETE'
  | 'S_WIDGETS_ACTIVATION_RULES_GET_BY_DASHBOARD'
  | 'S_WIDGETS_ACTIVATION_RULES_UPDATE'
  | 'S_WIDGETS_COPY'
  | 'S_WIDGETS_WIDGETS_CREATE'
  | 'S_WIDGETS_WIDGETS_DATA'
  | 'S_WIDGETS_WIDGETS_FILTER'
  | 'S_WIDGETS_WIDGETS_GET_BY_DASHBOARD'
  | 'S_WIDGETS_WIDGETS_GET_BY_DASHBOARD_LITE'
  | 'S_WIDGETS_WIDGETS_ONE'
  | 'S_WIDGETS_WIDGETS_QUERY_DATA'
  | 'S_WIDGETS_WIDGETS_STEP_DATA'
  | 'S_WIDGETS_WIDGETS_UPDATE'
  | 'S_WIDGETS_WIDGETS_UPDATE_POSITIONS'
