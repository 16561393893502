export enum EmitterEvent {
  JWT_EXPIRED_OR_INVALID = 'JWT_EXPIRED_OR_INVALID',
  TOKENS_UPDATED = 'TOKENS_UPDATED',
  LOGOUT = 'LOGOUT',
  DOMAIN_EXIT = 'DOMAIN_EXIT',
  DOMAIN_ENTER = 'DOMAIN_ENTER',
}

type EmitFunction = (payload: any) => void

class EventEmitter {
  private subscribers = {} as Record<EmitterEvent | string, EmitFunction[]>

  public on(event: EmitterEvent | string, func: EmitFunction) {
    if (!this.subscribers[event]) this.subscribers[event] = []
    this.subscribers[event].push(func)
  }

  public off(event: EmitterEvent | string, func: EmitFunction) {
    if (!this.subscribers[event]) return
    const index = this.subscribers[event].indexOf(func)
    if (index === -1) return
    this.subscribers[event].splice(index, 1)
  }

  public emit(event: EmitterEvent | string, payload?: any) {
    if (!this.subscribers[event]) return
    this.subscribers[event].forEach(f => f(payload))
  }
}

export function useEventEmitter(): EventEmitter {
  if ((window as any).eventEmitter) return (window as any).eventEmitter as EventEmitter
  const eventEmitter = new EventEmitter()
  ;(window as any).eventEmitter = eventEmitter
  return eventEmitter
}
