import BaseApi from '@/shared/api/base-api/BaseApi'
import { sha256 } from 'js-sha256'
import {
  Api3_AuthTypesDto,
  Api3_ExtendedJwtResponseDto,
  Api3_JwtRequestDto,
  Api3_LicenseLightDTO,
  Api3_UserActivityDTO,
} from '@/shared/api/types/api-v3-service-auth'
import {
  Api3_ChangePasswordDto,
  Api3_RestorePasswordDto,
  Api3_VerifyPasswordDto,
} from '@/shared/api/types/api-v3-service-user'

class AuthApi extends BaseApi {
  private authTypes!: Api3_AuthTypesDto

  public fetchAuthTypes(): Promise<Api3_AuthTypesDto> {
    if (this.authTypes) return Promise.resolve(this.authTypes)
    return this.get<Api3_AuthTypesDto>(`/auth/get_auth_types`, 'SERVICE_AUTH').then(authTypes => {
      this.authTypes = authTypes
      return authTypes
    })
  }

  public loginUser(username: string, password: string): Promise<Api3_ExtendedJwtResponseDto> {
    return this.fetchAuthTypes().then(() =>
      this.post<Api3_JwtRequestDto, Api3_ExtendedJwtResponseDto>(
        `/auth/login`,
        {
          username,
          password: (this.authTypes as any).allow_password_hashing ? sha256(password) : password,
        },
        'SERVICE_AUTH',
      ),
    )
  }

  public logoutUser(): Promise<void> {
    return this.get<void>(`/auth/logout`, 'SERVICE_AUTH')
  }

  public changePassword(old_password: string, new_password: string): Promise<void> {
    return this.put<Api3_ChangePasswordDto, void>(
      `/passwords/change`,
      {
        old_password,
        new_password,
      },
      'SERVICE_USER',
    )
  }

  public restorePassword(alias: string): Promise<void> {
    return this.post<Api3_RestorePasswordDto, void>(`/passwords/restore`, { alias }, 'SERVICE_USER')
  }

  public verifyPassword(user_guid: string, new_password: string, verification_code: string): Promise<void> {
    return this.put<Api3_VerifyPasswordDto, void>(
      `/passwords/verify`,
      { user_guid, new_password, verification_code },
      'SERVICE_USER',
    )
  }

  public fetchAllUsersActivity(): Promise<Api3_UserActivityDTO[]> {
    return this.get<Api3_UserActivityDTO[]>('/user-activity', 'SERVICE_AUTH')
  }

  public enterToDomain(id: string) {
    this.post(`/api/realms/${id}/enter`, {}, 'SERVICE_USER').finally()
  }

  public exitFromDomain(id: string) {
    this.post(`/api/realms/${id}/exit`, {}, 'SERVICE_USER').finally()
  }

  public fetchLicense() {
    return this.get<Api3_LicenseLightDTO>('/api/license/info', 'SERVICE_AUTH')
  }
}

let api: AuthApi
export default function useAuthApi() {
  if (!api) api = new AuthApi()
  return api
}
