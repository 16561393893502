import { createApp } from 'vue'
import { Quasar, quasarUserOptions } from '@/app/plugin/quasar'
import { store, router } from '@/app/providers'

export default class BiDialog {
  private static api: any = null

  static getApi() {
    const api = BiDialog.api
    if (!api) throw new Error('dialogApi not found')
    BiDialog.api = null
    return api
  }

  static open(payload: { component: any; componentProps?: any }) {
    let cbOk: any = null
    let cbDismiss: any = null
    let cbCancel: any = null
    let component: any = null
    let div: any = null

    const unmountComponent = () => {
      component.unmount()
      div.remove()
    }

    const onDialogOK = (result: any) => {
      unmountComponent()
      if (cbOk) cbOk(result)
      if (cbDismiss) cbDismiss()
    }

    const onDialogCancel = () => {
      unmountComponent()
      if (cbCancel) cbCancel()
      if (cbDismiss) cbDismiss()
    }

    const onDialogHide = () => {
      unmountComponent()
      if (cbDismiss) cbDismiss()
    }

    payload.component
      .__asyncLoader()
      .then(() => {
        BiDialog.api = { onDialogOK, onDialogCancel, onDialogHide }
        component = createApp(payload.component.__asyncResolved, payload.componentProps)
        component.use(Quasar, quasarUserOptions)
        component.use(router)
        component.use(store)
        component.config.globalProperties.$translate = (window as any).$translate
        div = document.createElement('div')
        document.getElementById('app')!.appendChild(div)
        component.mount(div)
        if (BiDialog.api) throw new Error('dialogApi not used')
      })
      .catch((err: Error) => {
        console.error(err)
      })

    const resultObj = {
      onOk: (cb: any) => {
        cbOk = cb
        return resultObj
      },
      onCancel: (cb: any) => {
        cbCancel = cb
        return resultObj
      },
      onDismiss: (cb: any) => {
        cbDismiss = cb
        return resultObj
      },
    }

    return resultObj
  }
}
