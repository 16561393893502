import Quasar from 'quasar/src/vue-plugin.js';import Notify from 'quasar/src/plugins/notify/Notify.js';import Loading from 'quasar/src/plugins/loading/Loading.js';
import langRu from 'quasar/lang/ru'

import './quasar.scss'
import '@quasar/extras/roboto-font/roboto-font.css'
import '@quasar/extras/material-icons/material-icons.css'
import '@quasar/extras/material-icons-outlined/material-icons-outlined.css'
import '@quasar/extras/mdi-v7/mdi-v7.css'

const quasarUserOptions = {
  lang: langRu,
  config: {
    dark: localStorage.getItem('theme') === 'dark',
  },
  plugins: {
    Notify,
    Loading,
  },
}

export { Quasar, quasarUserOptions }
