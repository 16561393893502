const localeLoader: Record<string, () => any> = {
  en: () => import('./locales/dictionary-en'),
  ru: () => import('./locales/dictionary-ru'),
}

export async function loadLanguage(lang: string) {
  // @ts-ignore
  window.$translate = {}
  // @ts-ignore
  window.$appInstance.config.globalProperties.$translate = {}
  // @ts-ignore
  window.$translate = (await localeLoader[lang]()).words
  // @ts-ignore
  window.$appInstance.config.globalProperties.$translate = window.$translate
}
