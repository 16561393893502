import axios, { AxiosResponse } from 'axios'
import { EmitterEvent, useEventEmitter } from '@/shared/utils/event-emitter/useEventEmitter'
import { apiServiceUrls } from '../urls/serviceUrls'
import { Api3_JwtResponseDto } from '@/shared/api/types/api-v3-service-auth'

const eventEmitter = useEventEmitter()
let tokensRefreshing = false

export function refreshTokens(target: any, methodName: string, descriptor: PropertyDescriptor) {
  const originalMethod = descriptor.value
  descriptor.value = function (...args: any[]) {
    const accessToken = getAccessToken()
    if (!accessToken) {
      return originalMethod.apply(this, args)
    }
    const soonMinutes = 1
    const isExpiredSoon = isTokenExpired(accessToken, Date.now() + soonMinutes * 60 * 1000)
    if (isExpiredSoon) {
      console.log('token expired soon')
      if (tokensRefreshing) {
        return new Promise((resolve: any, reject: any) => {
          const doneEvent = EmitterEvent.TOKENS_UPDATED
          const failEvent = EmitterEvent.JWT_EXPIRED_OR_INVALID
          const done = () => {
            eventEmitter.off(doneEvent, done)
            eventEmitter.off(failEvent, fail)
            resolve(originalMethod.apply(this, args))
          }
          const fail = () => {
            eventEmitter.off(doneEvent, done)
            eventEmitter.off(failEvent, fail)
            reject(new Error())
          }
          eventEmitter.on(doneEvent, done)
          eventEmitter.on(failEvent, fail)
        })
      }
      tokensRefreshing = true
      const refresh_token = getRefreshToken()
      console.log('tokens refreshing...')
      return axios({
        method: 'post',
        url: `${apiServiceUrls.SERVICE_AUTH}/auth/refresh`,
        data: { refresh_token },
      })
        .then((response: AxiosResponse<Api3_JwtResponseDto>) => {
          const { access_token, refresh_token } = response.data
          eventEmitter.emit(EmitterEvent.TOKENS_UPDATED, { access_token, refresh_token })
          console.log('tokens updated successfully')
          tokensRefreshing = false
          return originalMethod.apply(this, args)
        })
        .catch((err: Error) => {
          tokensRefreshing = false
          eventEmitter.emit(EmitterEvent.JWT_EXPIRED_OR_INVALID)
          console.log('tokens error', err)
          throw err
        })
    } else {
      return originalMethod.apply(this, args)
    }
  }
}

function isTokenExpired(token: string, ts = Date.now()) {
  try {
    const tokenData = JSON.parse(atob(token.split('.')[1]))
    const expirationTime = tokenData.exp * 1000
    return ts >= expirationTime
  } catch (error) {
    return true
  }
}

function getAccessToken() {
  return localStorage.getItem('access_token') || ''
}

function getRefreshToken() {
  return localStorage.getItem('refresh_token') || ''
}
